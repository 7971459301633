<template>
  <v-container id="learning" class="mt-15">
    <h2 class="font-weight-medium ngOrange--text pt-2">Learning Centre for Attachment Students</h2>
    <v-row class="mt-3">
      <v-col cols="12" sm="6" class="d-flex">
        <v-hover
          v-slot="{ hover }"
          close-delay="20"
        >
          <v-card
            :elevation="hover ? 4 : 1"
            :class="{ 'on-hover': hover }"
          >
            <v-img
              src="https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/learning.jpg"
              class="rounded rounded-b-0 d-block d-sm-none"
            />
            <v-card-text>
              <p>
                We provide internship programme to students!
              </p>
              <p>
                Our Company is an ideal place for students to intern at and gain hands-on career experience, develop an understanding of ethics in the workplace and cultivate a professional image for future career success.
              </p>
              <p>
                If you are interested in joining our internship programme, please send your inquiry and proposal to our email niagamas.insurance@gmail.com
              </p>
              <p>
                Or you can drop us a general inquiry below and we will get back to you as soon as possible.
              </p>
            </v-card-text>
            <v-card-actions>
              <v-btn
                class="rounded-pill px-8 white--text ml-3 mb-3"
                color="#007ED9"
                depressed
                href="tel:6737261013"
                target="_blank"
              >
                <v-icon class="mr-3">
                  mdi-phone
                </v-icon>
                Contact Now
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" sm="6" class="d-flex">
        <v-img
          src="https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/learning.jpg"
          class="rounded d-none d-sm-block"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Learning',
}
</script>

<style scoped>
.v-card {
  transition: 0.2s ease-out;
}
</style>
