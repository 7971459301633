<template>
  <div class="banner">
    <v-row justify="center" class="mx-md-10">
      <v-col
        cols="12"
        md="6"
        class="pb-0"
      >
        <div class="d-block d-md-none">
          <v-select
            v-model="selectedItem"
            :items="branchItems"
            label="Select a branch"
            dense
            solo
            class="font-weight-medium body-2"
            height="45"
            v-on:change="centerMap(branchItems.indexOf(selectedItem))"
          ></v-select>
        </div>
        <div class="d-none d-md-block">
          <v-card
            v-for="(item,i) in branches"
            :key="i"
            class="d-flex align-center mx-auto mb-3"
            min-height="60"
            :color="selected == i ? 'ngOrange' : 'white'"
            elevation="2"
            v-ripple="{ class: `ngOrange--text` }"
            @click="infoWindow.open=false; centerMap(i); selected = i"
          >
            <v-icon
              class="ma-2"
              :class="selected == i ? 'white--text' : 'grey--text'"
            >
              mdi-map-marker-outline
            </v-icon>
            <v-card-text
              class="pa-1"
              :class="selected == i ? 'white--text' : 'grey--text'"
            >
              <p class="mb-0">
                {{ item.first_line }} {{ item.second_line }}, {{ item.title }}
                <br>
                <span class="d-flex">
                  Tel: {{ item.contact_number }}
                  <v-spacer></v-spacer>
                  <div class="hover-pointer">
                    <div
                      class="d-flex primary--text pr-2"
                      @click="openGoogleMap(item.latitude, item.longitude)"
                    >
                      <div
                        class="font-weight-medium mr-1"
                        :class="selected == i ? 'white--text' : 'blue--text'"
                      >
                        Google Maps
                      </div>
                      <v-icon
                        :color="selected == i ? 'white' : 'blue'"
                        small
                      >
                        mdi-open-in-new
                      </v-icon>
                    </div>
                  </div>
                </span>
              </p>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <GmapMap
          :center="center"
          :zoom="15"
          :options="{
            mapTypeControl: false,
            streetViewControl: false,
          }"
          style="width: 100%; height: 400px"
        >
          <GmapMarker
            :key="index"
            v-for="(m, index) in branches"
            :position="m"
            @click="openInfoWindow(m)"
          />
          <gmap-info-window
            v-if="infoWindow.image_url != 0"
            :options="{
              maxWidth: 300,
              pixelOffset: { width: 0, height: -35 }
            }"
            :position="infoWindow.position"
            :opened="infoWindow.open"
            @closeclick="infoWindow.open=false"
          >
            <v-img :src="infoWindow.image_url" />
            <div class="gmap-link white pa-1 hover-pointer rounded-tr">
              <div
                class="d-flex primary--text"
                @click="openGoogleMap(infoWindow.position.lat, infoWindow.position.lng)"
              >
                <div class="font-weight-medium mr-1">
                  Google Maps
                </div>
                <v-icon
                  color="primary"
                  small
                >
                  mdi-open-in-new
                </v-icon>
              </div>
            </div>
          </gmap-info-window>
        </GmapMap>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'BannerBranch',
  data: () => ({
    branches: [],
    center: {
      lat: 0,
      lng: 0,
    },
    selected: 0,
    branchItems: [],
    selectedItem: '',
    images: [
      { image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/branches/branch-tutong.jpeg' },
      { image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/branches/branch-lambak.jpeg' },
      { image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/branches/branch-bengkurong.jpeg' },
      { image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/branches/branch-petani-tutong.jpeg' },
      { image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/branches/branch-belait.jpeg' },
      { image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/branches/branch-beribi.jpeg' },
      { image_url: '' },
    ],
    infoWindow: {
      position: {lat: 0, lng: 0},
      open: false,
      template: ''
    }
  }),
  created() {
    this.fetchBranches()
  },
  methods: {
    async fetchBranches() {
      const apiUrl = process.env.VUE_APP_API_URL
      await axios.get(apiUrl + '/api/v1/website/branches').then(res => {
          this.branches = res.data.data.filter(b => b.first_line && b.second_line && b.contact_number)
          this.setMapMarkerOptions()
          this.setMarkers()
          this.setDropdownItem()
          this.centerMap(0)
        },
        () => {}
      )
    },
    centerMap(i) {
      this.center = this.branches[i]
      this.selectedItem = this.branchItems[i]
      this.openInfoWindow(this.branches[i])
    },
    setMarkers() {
      this.branches.forEach(branch => {
        branch.lat = Number(branch.latitude)
        branch.lng = Number(branch.longitude)
      })
    },
    setDropdownItem() {
      this.branches.forEach(branch => {
        this.branchItems.push(`${branch.first_line} ${branch.second_line}`)
      })
    },
    setMapMarkerOptions() {
      this.branches.forEach((branch, index) => {
        branch.options = {
          url: this.images[index].image_url
        }
      })
    },
    openInfoWindow(marker) {
      this.infoWindow.position = { lat: marker.lat, lng: marker.lng }
      this.infoWindow.image_url = marker.options.url
      this.infoWindow.open = !this.infoWindow.open
    },
    openGoogleMap(lat, lng) {
      window.open(`https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=${lat},${lng}`, '_blank')
    },
  }
}
</script>

<style scoped>
.v-card:hover:before,
.v-card:focus:before {
  display: none;
}
</style>
