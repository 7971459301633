<template>
  <v-container id="product" class="mt-15">
    <h2 class="font-weight-medium ngOrange--text pt-2">Our Products and Services</h2>
    <v-row class="mt-3 justify-center">
      <v-col
        v-for="(item,i) in products"
        :key="i"
        cols="12"
        sm="6"
        md="4"
      >
        <v-hover
          v-slot="{ hover }"
          close-delay="20"
        >
          <v-card
            height="100%"
            :elevation="hover ? 4 : 1"
            :class="{ 'on-hover': hover }"
            class="mx-auto d-flex flex-column"
          >
            <v-img
              :src="item.image_url"
              min-height="275"
              class="rounded rounded-b-0"
            />
            <v-card-text>
              <h2 class="font-weight-medium mb-3">
                {{ item.name }}
              </h2>
              <p class="mb-0">
                {{ item.description }}
              </p>
            </v-card-text>
            <v-spacer></v-spacer>
            <v-card-actions>
              <v-btn
                text
                color="ngBlue"
                @click="reveal.push(item.name)"
              >
                Know More
              </v-btn>
            </v-card-actions>
            <v-expand-transition>
              <v-card
                v-if="checkReveal(item.name)"
                class="transition-fast-in-fast-out v-card--reveal d-flex flex-column"
                style="height: 100%;"
              >
                <v-card-text>
                  <h2 class="font-weight-medium mb-3">
                    {{ item.name }}
                  </h2>
                  <p class="pb-3">
                    {{ item.description }}
                  </p>
                  <v-row v-if="item.tags">
                    <v-col
                      v-for="(tag,i) in item.tags"
                      :key="i"
                      cols="4"
                      class="text-center bg-primary py-0"
                    >
                      <v-icon>
                        {{ tag.icon }}
                      </v-icon>
                      <p class="text-caption text-uppercase">{{ tag.name }}</p>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-spacer></v-spacer>
                <v-chip-group class="pdfChip mx-4" column>
                  <v-chip
                    v-for="(pdf, i) in item.pdfs"
                    :key="i"
                    class="px-5 white--text"
                    color="#007ED9"
                    @click="dialog = true; pdfView = pdf.content"
                  >
                    {{ pdf.label }}
                  </v-chip>
                </v-chip-group>
                <v-card-actions class="pb-2">
                  <v-btn
                    text
                    color="ngBlue"
                    @click="removeReveal(item.name)"
                  >
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      :width="$vuetify.breakpoint.mdAndDown ? '80%' : '60%'"
    >
      <v-card>
        <v-card-text class="d-flex align-center pa-1">
          <v-btn small depressed text :href="pdfView" target="_blank">
            View full pdf <v-icon small class="pl-2">mdi-open-in-new</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-icon large @click="dialog = false">
            mdi-close
          </v-icon>
        </v-card-text>
        <div style="overflow: auto; -webkit-overflow-scrolling: touch;">
          <iframe :src="pdfView"></iframe>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'Product',
  data: () => ({
    dialog: false,
    pdfView: '',
    reveal: [],
    products: [
      {
        name: 'Motor Insurance',
        image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/products/product1.jpg',
        description: 'According to the Motor Vehicles Insurance (Third Party Risks) Act, Chapter 90, all motor vehicles in Brunei must be insured. Covers for damage to vehicles and properties, bodily injury and liability in the event of road accidents.',
        pdfs: [
          {
            label: 'Insurans Islam Taib EBrochure',
            content: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/pdfs/taib-motor.pdf',
          },
          {
            label: 'Takaful Brunei Am EBrochure',
            content: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/pdfs/tba-motor.pdf',
          },
        ],
        tags: [
          { name: 'Excess Waiver', icon: 'mdi-currency-usd'},
          { name: 'Passenger Risk', icon: 'mdi-seat-passenger'},
          { name: 'Glass Breakage', icon: 'mdi-car-windshield-outline'},
          { name: 'Flood', icon: 'mdi-waves'},
          { name: 'Accessories', icon: 'mdi-car-outline'},
        ],
      },
      {
        name: 'Travel Insurance',
        image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/products/product2.jpg',
        description: 'Provides compensation for individual(s) or family travelling abroad, either on vacation, business or work related trip in the event of injuries, disabilities or death caused by sudden and unforeseen accident which also include Covid-19',
        pdfs: [
          {
            label: 'Insurans Islam Taib EBrochure',
            content: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/pdfs/taib-travel.pdf',
          },
          {
            label: 'Takaful Brunei Am EBrochure',
            content: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/pdfs/tba-travel.pdf',
          },
        ],
        tags: [
          { name: 'Medical Expenses', icon: 'mdi-medical-bag'},
          { name: 'Baggage Issues', icon: 'mdi-bag-checked'},
          { name: 'Trip Cancellation', icon: 'mdi-airplane-alert'},
          { name: 'Loss of Documents', icon: 'mdi-file-document-alert-outline'},
        ],
      },
      {
        name: 'Fire Insurance',
        image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/products/product3.jpg',
        description: 'Covers cost of rebuilding properties ranging from houses, premises to warehouses in the event of fires. May also insure assets, rentals and stocks.',
        pdfs: [
          {
            label: 'Insurans Islam Taib EBrochure',
            content: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/pdfs/taib-fire.pdf',
          },
          {
            label: 'Takaful Brunei Am EBrochure',
            content: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/pdfs/tba-fire.pdf',
          },
        ],
        tags: [
          { name: 'Fire', icon: 'mdi-fire'},
          { name: 'Flood', icon: 'mdi-waves'},
          { name: 'Theft', icon: 'mdi-currency-usd'},
          { name: 'Storm', icon: 'mdi-lightning-bolt-outline'},
          { name: 'Explosion', icon: 'mdi-propane-tank-outline'},
          { name: 'Malicious Damage', icon: 'mdi-close-octagon'},
        ],
      },
      {
        name: 'Workmen Compensation',
        image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/products/product4.jpg',
        description: 'According to the Workmen’s Compensation Act, employers must provide workmen compensation for all workers earning below $750pm, including construction workers and domestic helpers. Covers for work-related accidental injuries arising during working hours which result in permanent disability or death.',
        pdfs: [
          {
            label: 'Insurans Islam Taib EBrochure',
            content: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/pdfs/taib-workmen.pdf',
          },
          {
            label: 'Takaful Brunei Am EBrochure',
            content: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/pdfs/tba-workmen.pdf',
          },
          {
            label: 'Takaful Brunei Am Domestic Helper EBrochure',
            content: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/pdfs/tba-domestic-helper.pdf',
          },
        ],
        tags: [
          { name: 'Medical Expenses', icon: 'mdi-medical-bag'},
          { name: 'Disability', icon: 'mdi-account-injury-outline'},
          { name: 'Death', icon: 'mdi-account-outline'},
        ],
      },
      {
        name: 'Public Liability',
        image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/products/product5.jpg',
        description: 'Covers legal liability for compensation claimed by third parties who were accidentally injured or whose properties were damaged due to the insured’s business activities.',
        pdfs: [
          {
            label: 'Takaful Brunei Am EBrochure',
            content: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/pdfs/tba-public.pdf',
          },
        ],
        tags: [
          { name: '3rd Party Injury', icon: 'mdi-account-injury-outline'},
          { name: '3rd Party Property Damage', icon: 'mdi-alert-circle-outline'},
          { name: 'Legal Expenses', icon: 'mdi-gavel'},
        ],
      },
      {
        name: 'Contractor All Risk',
        image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/products/product6.jpg',
        description: 'Covers contractors against loss or damage to contract works and liabilities to third parties due to work done as stipulated in the contract. Suitable for tendered government and private projects such as construction projects, piling works, road upgrades etc.',
        pdfs: [
        ],
        tags: [
          { name: 'Material Damage', icon: 'mdi-glass-fragile'},
          { name: '3rd Party Injury', icon: 'mdi-account-injury-outline'},
          { name: '3rd Party Property Damage', icon: 'mdi-alert-circle-outline'},
        ],
      },
    ]
  }),
  methods: {
    checkReveal(productName) {
      return this.reveal.includes(productName)
    },
    removeReveal(productName) {
      let index = this.reveal.indexOf(productName)
      this.reveal.splice(index,1)
    }
  }
}
</script>

<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
.v-card {
  transition: 0.2s ease-out;
}
.pdfChip {
  height: auto;
  position: absolute;
  bottom: 60px;
}
iframe {
  width: 100%;
  height: 80vh;
  overflow-y: scroll !important;
}
@media (min-width: 960px) and (max-width: 1263px) {
  .pdfChip {
    height: 100px;
    bottom: 40px;
  }
}
</style>