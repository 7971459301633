<template>
  <div class="banner">
    <v-row class="rowTitle ml-0 ml-md-8">
      <v-col
        xs="12"
        sm="10"
        md="5"
        class="white--text ml-0 ml-md-15"
      >
        <div>
          <h2 class="font-weight-regular">
            Connect With Us
          </h2>
          <p class="font-weight-light">
            From any information or quotation about Insurance you can contact with us. We would love to hear from you!
          </p>
          <v-btn
            class="rounded-pill px-8 white--text"
            color="#007ED9"
            depressed
            @click="$vuetify.goTo('#contact')"
          >
            Chat Now
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'BannerContact',
}
</script>

<style scoped>
.rowTitle {
  padding-top: 130px;
}
</style>
