<template>
  <v-container id="branch" class="mt-15">
    <h2 class="font-weight-medium ngOrange--text pt-2">Our Branches</h2>
    <v-row class="my-5 px-3">
      <GmapMap
        :center="center"
        :zoom="15"
        :options="{
          mapTypeControl: false,
          streetViewControl: false,
        }"
        class="mx-auto"
        style="width: 100%; height: 400px"
      >
        <GmapMarker
          :key="index"
          v-for="(m, index) in branches"
          :position="m"
          @click="openInfoWindow(m)"
        />
        <gmap-info-window
          v-if="infoWindow.image_url != 0"
          :options="{
            maxWidth: 300,
            pixelOffset: { width: 0, height: -35 }
          }"
          :position="infoWindow.position"
          :opened="infoWindow.open"
          @closeclick="infoWindow.open=false"
        >
          <v-img :src="infoWindow.image_url" />
          <div class="gmap-link white pa-1 hover-pointer rounded-tr">
            <div
              class="d-flex primary--text"
              @click="openGoogleMap(infoWindow.position.lat, infoWindow.position.lng)"
            >
              <div class="font-weight-medium mr-1">
                Google Maps
              </div>
              <v-icon
                color="primary"
                small
              >
                mdi-open-in-new
              </v-icon>
            </div>
          </div>
        </gmap-info-window>
      </GmapMap>
    </v-row>
    <v-row class="mt-5 mb-3 justify-center">
      <v-col
        v-for="(item,i) in branches"
        :key="i"
        cols="12"
        sm="6"
        class="d-flex"
      >
        <v-card
          class="d-flex align-center mx-auto"
          :color="selected == i ? 'ngOrange' : 'white'"
          height="100%"
          width="100%"
          elevation="2"
          v-ripple="{ class: `ngOrange--text` }"
          @click="infoWindow.open=false; centerMap(i), selected = i"
        >
          <v-icon
            class="ma-2"
            :class="selected == i ? 'white--text' : 'grey--text'"
            x-large
          >
            mdi-map-marker-outline
          </v-icon>
          <v-card-text :class="{'white--text': selected == i }">
            <p>
              {{ item.first_line }}, {{ item.second_line }}, {{ item.title }}
            </p>
            <p class="mb-0">
              <span class="d-flex">
                Tel: {{ item.contact_number }}
                <v-spacer></v-spacer>
                <div class="hover-pointer">
                  <div
                    class="d-flex primary--text pr-2"
                    @click="openGoogleMap(item.latitude, item.longitude)"
                  >
                    <div
                      class="font-weight-medium mr-1"
                      :class="selected == i ? 'white--text' : 'blue--text'"
                    >
                      Google Maps
                    </div>
                    <v-icon
                      :color="selected == i ? 'white' : 'blue'"
                      small
                    >
                      mdi-open-in-new
                    </v-icon>
                  </div>
                </div>
              </span>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Branch',
  data: () => ({
    branches: [],
    center: {
      lat: 0,
      lng: 0,
    },
    selected: 0,
    images: [
      { image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/branches/branch-tutong.jpeg' },
      { image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/branches/branch-lambak.jpeg' },
      { image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/branches/branch-bengkurong.jpeg' },
      { image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/branches/branch-petani-tutong.jpeg' },
      { image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/branches/branch-belait.jpeg' },
      { image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/branches/branch-beribi.jpeg' },
      { image_url: '' },
    ],
    infoWindow: {
      position: {lat: 0, lng: 0},
      open: false,
      template: ''
    }
  }),
  created() {
    this.fetchBranches()
  },
  methods: {
    async fetchBranches() {
      const apiUrl = process.env.VUE_APP_API_URL
      await axios.get(apiUrl + '/api/v1/website/branches').then(res => {
          this.branches = res.data.data.filter(b => b.first_line && b.second_line && b.contact_number)
          this.setMapMarkerOptions()
          this.setMarkers()
          this.centerMap(0)
        },
        () => {}
      )
    },
    centerMap(i) {
      this.center = this.branches[i]
      this.openInfoWindow(this.branches[i])
    },
    setMarkers() {
      this.branches.forEach(branch => {
        branch.lat = Number(branch.latitude)
        branch.lng = Number(branch.longitude)
      })
    },
    setMapMarkerOptions() {
      this.branches.forEach((branch, index) => {
        branch.options = {
          url: this.images[index].image_url
        }
      })
    },
    openInfoWindow(marker) {
      this.infoWindow.position = { lat: marker.lat, lng: marker.lng }
      this.infoWindow.image_url = marker.options.url
      this.infoWindow.open = !this.infoWindow.open
    },
    openGoogleMap(lat, lng) {
      window.open(`https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=${lat},${lng}`, '_blank')
    },
  }
}
</script>

<style>
.gmap-link {
  position: fixed;
  bottom: 5px;
}
</style>