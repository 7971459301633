<template>
  <div id="promo">
    <v-carousel
      height="800"
      hide-delimiter-background
      :show-arrows="$vuetify.breakpoint.smAndDown ? false : true"
    >
      <v-carousel-item
        v-for="(item,i) in banners"
        :key="i"
        :src="item.image_url"
      >
        <div
          v-if="item.component"
          :class="item.style"
        >
          <v-row justify="center">
            <v-col
              cols="10"
              md="12"
              lg="10"
              xl="8"
            >
              <BannerLogo v-if="item.component == 'BannerLogo'" />
              <BannerBranch v-else-if="item.component == 'BannerBranch'" />
              <BannerContact v-else-if="item.component == 'BannerContact'" />
              <BannerProductService v-else-if="item.component == 'BannerProductService'" />
              <BannerDelivery v-else-if="item.component == 'BannerDelivery'" />
            </v-col>
          </v-row>
        </div>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import BannerLogo from '@/components/banners/BannerLogo'
import BannerBranch from '@/components/banners/BannerBranch'
import BannerContact from '@/components/banners/BannerContact'
import BannerProductService from '@/components/banners/BannerProductService'
import BannerDelivery from '@/components/banners/BannerDelivery'

export default {
  name: 'Promo',
  components: {
    BannerLogo,
    BannerBranch,
    BannerContact,
    BannerProductService,
    BannerDelivery,
  },
  data: () => ({
    banners: [
      {
        component: 'BannerLogo',
        style: 'overlay',
        image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/banners/bg1.jpg',
      },
      {
        component: 'BannerBranch',
        style: 'overlay',
        image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/banners/bg2.jpg',
      },
      {
        component: 'BannerContact',
        style: 'gradient',
        image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/banners/bg3.jpg',
      },
      {
        component: 'BannerProductService',
        style: 'overlay',
        image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/banners/bg4.jpg',
      },
      {
        component: 'BannerDelivery',
        style: 'gradient',
        image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/banners/bg5.jpeg',
      }
    ]
  })
}
</script>

<style>
body {
  overflow-x: hidden;
}
.overlay {
  background-color: rgba(84, 163, 220, 0.75);
  height: 820px;
}
.gradient {
  background: linear-gradient(to right, rgba(84, 163, 220, 0.75), transparent);
  height: 820px;
}
.banner {
  padding: 130px 0;
}
.hover-pointer {
  cursor: pointer;
}
.v-carousel__controls {
  margin-bottom: 30px !important;
}
@media (max-width: 600px) {
  .banner {
    padding: 90px 0;
  }
}
</style>
