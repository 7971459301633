<template>
  <v-container id="about" class="mt-5 mt-sm-15">
    <h2 class="font-weight-medium ngOrange--text pt-2">About us</h2>
    <iframe
      src="https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/videos/appreciation-night.mp4"
      width="100%"
      :height="getHeight"
      class="rounded-lg mt-3"
    ></iframe>
    <v-row class="mt-2">
      <v-col cols="12" sm="6" class="d-flex">
        <v-hover
          v-slot="{ hover }"
          close-delay="20"
        >
          <v-card
            :elevation="hover ? 4 : 1"
            :class="{ 'on-hover': hover }"
          >
            <v-img
              src="https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/about.jpg"
              class="rounded rounded-b-0 d-block d-sm-none"
            />
            <v-card-text>
              <p>
                Niagamas Insurance Services Sdn Bhd was founded in 2016 by Madam Yong, a veteran with nearly 40 years of experience in the insurance industry. The agency has since expanded to 7 branches around Brunei.
              </p>
              <p>
                We are a registered insurance and Takaful agent for Takaful Brunei Am, Insurans Islam TAIB General Takaful and National Insurance Company Berhad. Our clients are offered a wealth of choices of various insurance packages.
              </p>
              <p>
                We are proud to be servicing more than 5,000 clients and processing $1.5 million worth of premiums annually.
              </p>
              <p class="mb-0">
                We are now keen to leverage our expertise in general insurance and offer prospective corporate clients our signature personalised, value-based and value-added insurance services.
              </p>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" sm="6" class="d-none d-sm-flex">
        <v-img
          src="https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/about.jpg"
          class="rounded"
        />
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col cols="12" sm="6" class="d-flex">
        <v-hover
          v-slot="{ hover }"
          close-delay="20"
        >
          <v-card
            :elevation="hover ? 4 : 1"
            :class="{ 'on-hover': hover }"
          >
            <v-card-text>
              <h2 class="font-weight-medium mb-3">
                Our Mission
              </h2>
              <p class="mb-0">
                We strive to deliver tailored and affordable general
                insurance solutions to our customers’s satisfaction.
              </p>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" sm="6" class="d-flex">
        <v-hover
          v-slot="{ hover }"
          close-delay="20"
        >
          <v-card
            :elevation="hover ? 4 : 1"
            :class="{ 'on-hover': hover }"
          >
            <v-card-text>
              <h2 class="font-weight-medium mb-3">
                Our Vision
              </h2>
              <p class="mb-0">
                We want to be the market leader in insurance agency
                services in Brunei that provides the best value-based
                insurance policies, whilst aligning with the goals and
                interests of our customers and principals.
              </p>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'About',
  data() {
    return {
      windowWidth: window.innerWidth
    }
  },
  computed: {
    getHeight() {
      if (this.windowWidth >= 1440) return '697px'
      if (this.windowWidth >= 1024) return '494px'
      if (this.windowWidth >= 768) return '383px'
      else return '250px'
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  }
}
</script>

<style scoped>
.v-card {
  transition: 0.2s ease-out;
}
</style>
